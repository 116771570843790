import axios from 'axios'

const Service = axios.create({
  baseURL: 'https://api.shuweitel.com/api',
  timeout: 20000
})

// 請求攔截器
Service.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 響應攔截器
Service.interceptors.response.use(
  (res) => {
    const code = res.data.status
    if (code === 200) {
      return res.data
    } else {
      return res.data
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default Service
