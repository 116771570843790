import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [{
    path: '/',
    redirectr: '/code',
    component: () => import('../views/redemption.vue')
  },
  {
    path: '/code',
    name: 'redEmption',
    component: () => import('../views/redemption.vue')
  },
  {
    path: '/code/details',
    name: 'redName',
    component: () => import('../views/red.vue')
  },
  {
    path: '/qrcode',
    name: 'qrCode',
    component: () => import('../views/qrcode.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  document.title = '兌換二維碼'
  next()
})

export default router
